
.banner_container{
    z-index: 3333;
    position: absolute;
    z-index: 50;
    width: 100%;
    height: 100vh;
    /* clip-path: inset(0 0 0 0); */
    background-color: #171b20;
    /* cursor: wait; */
}
@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@700&family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.title{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 40vh;
    justify-content: center;
    align-items: center;
    gap:2rem;
    color: rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    z-index: 200;
}
 h1,
 h2 {
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 0;
     /* font-family: Arial, Helvetica, sans-serif; */
    font-family: 'Share Tech Mono', monospace;
    font-weight: 500;
    color: white;
 }

 h1{
    font-size: clamp(1.5rem,5rem,7rem);
 }
h2{
    font-size: clamp(.5rem, 1.5rem, 2.5rem);
}

.logo_content{
    height: 50%;
    width: 50%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo {
        height: 10vh
    }
    .logo img {
        object-fit: contain;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    h1 {
            font-size: 3rem;
        }
    h2{
        font-size: .8rem;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
   
}

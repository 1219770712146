/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}
/* .App{
  /* position: sticky; */

/* Hide scrollbar for IE, Edge, and Firefox */
/* You can also use 'display: none;' to hide the scrollbar in Firefox */
/* Note that hiding scrollbars may affect user experience and accessibility */
* {
  scrollbar-width: none;
}


@media (min-width:2500px){
  p{
    font-size: 1.5rem;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.description__section {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

.description__text {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    /* padding-inline: clamp(1rem, 12vw, 10rem); */
    padding-block: clamp(4rem, 12vw, 6rem);
}

.description__text p {
    text-indent: 1rem;
    display: block;
}

.description__image {
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.description__image.small {
    height: 20rem;
}
p{
font-family: 'Share Tech Mono', monospace;
color: white;
}


@media (min-width: 1024px) {
    .description__section {
        width: 100%;
        flex-direction: row;
    }

    .description__text {
        gap: 2rem;
        width: 50%;
        height: 100%;
        padding-inline: clamp(1rem, 12vw, 6rem);
        padding-block: 0;
    }

    .description__text p {
        text-indent: 2rem;
    }

    .description__image {
        width: 50%;
        height: 40rem;
    }

    .description__image.small {
        height: 20rem;
    }

    .description__image.medium {
        height: 30rem;
    }

    .description__image.big {
        height: 50rem;
    }

    .description__section:nth-child(even) {
        flex-direction: row-reverse;
    }
}
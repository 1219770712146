@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
.button{
    background-color: black;
    color: white;
    border-radius: .5rem;
    border-color: white;
    font-family: 'Share Tech Mono',
    monospace;
    padding: 1rem;
}

.button:hover{
    color: black;
    background-color: white;
    border: black;
    transition: 1s;
}


.intro__button {
    background-color: rgb(0, 0, 0,.3);
    color: white;
    border-radius: .5rem;
    font-family: 'Share Tech Mono',
        monospace;
    padding: 1rem;
    border-color: black;
}

.intro__button:hover {
    opacity: 1;
    transition: 1s;
}




.arrow {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    /*   border:solid 1px white; */
    transition: 0.5s;
    overflow: hidden;
}

.arrow:hover {
    /*  animation:borderAni 5s cubic-bezier(0, 0.6, 1, 0.4); */
    border: solid 2px white;
    border-radius: 50%;
}

.arrow:after {
    position: absolute;
    display: block;
    content: "";
    color: white;
    width: 20px;
    height: 15px;
    /*   border:solid 1px;  */
    top: -1px;
    border-bottom: solid 2px;
    transform: translatex(4px);
}

.arrow:before {
    position: absolute;
    display: block;
    content: "";
    color: white;
    width: 8px;
    height: 8px;
    /*   border:solid 1px;  */
    border-top: solid 2px;
    border-left: solid 2px;
    top: 50%;
    left: 2px;
    transform-origin: 0% 0%;
    transform: rotatez(-45deg);

}

.arrow:hover:before {
    animation: aniArrow01 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.arrow:hover:after {
    animation: aniArrow02 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}


@keyframes aniArrow01 {
    0% {
        transform: rotatez(-45deg) translateY(30px) translateX(30px);
    }

    100% {
        transform: rotatez(-45deg) translateY(-35px) translateX(-35px);
    }
}


@keyframes aniArrow02 {
    0% {
        transform: translateX(45px);
    }

    100% {
        transform: translateX(-44px);
    }
}

@keyframes borderAni {
    0% {
        border: solid 2px white;
    }

    100% {
        border: solid 2px white;
        border-radius: 50%;
    }
}
.Vex__Robotics{
    height: 90vh;
    width: 100vw;
}

.slider__container{
    height: 100vh;
    width: 400vw;
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 900px) {
    .slider__container {
            height: 400vh;
            width: 100vw;
            display: flex;
            flex-direction: column;
        }
                .Vex__Robotics {
                    height: 90vh;
                    width: 100vw;
                }
}

.intro-section{
    width: 100%;
    height:110vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-size: cover;

    
}

.logo{
    height: 30vh;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
.logo{
    height:20vh;
    width:20vh
}

}
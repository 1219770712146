@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@700&family=Share+Tech+Mono&display=swap');

.robot__intro{
    height: 110vh;
    display: grid;
    grid-template-columns: repeat(10,1fr);
    justify-items: center;
    background-color:black
}

.robot__title{
    position: relative;
    grid-column: 2/10;
    grid-row: 1;
    z-index: 50;
    height: 35%;
    text-align: center;
    font-weight: 900;
    display: flex;
    padding: 10%;

}
.robot__title h1{
    opacity: 1;
    padding: .5rem;
    font-size: 2rem;
    font-size: 4rem;
    font-family: 'Oxanium', cursive;
}
.robot__parallax{
    grid-row: 1;
    grid-column: 1/11;
    /* clip-path: polygon(0 0,100% 0, 100% 0, 0 0); */
    
    overflow: hidden;
}

@media (max-width:900px) {

    .robot__intro {
            height: 110%;
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            grid-template-rows: repeat(10,1fr);
            justify-items: center;
            background-color: black
        }
    .robot__title{
        margin-top: 10%;
        grid-area: 6/1/9/11;
        box-sizing: border-box;
    }

        .robot__title h1 {
            opacity: 1;
            padding: 0%;
            font-size: 4rem;
        }

    .robot__parallax{
        grid-area: 1/1/-1/11;
    }


}

